<template>
  <tabs nav-class="nav-side" starting-tab="setup" :full-height="true">
    <tab
      :position="1"
      icon="pencil"
      id="setup"
      class="form-horizontal setup-tab"
      style="padding: 20px"
    >
      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('smsToolboxLargeDefaultLanguage') }}:</label>
          <language-selector
            class="sms-select"
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('smsToolboxLargeAdditionalLanguages') }}:</label>
          <multi-language-selector
            :value="additionalLanguages"
            @input="setLanguages"
            :disabled="!isAvailable"
          />
        </div>
      </div>

      <national-exclusion-check-settings
        v-if="
          $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
          $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
        "
        v-model="config.exclusion"
        :disabled="readOnly"
      />

      <div class="form-group" v-if="config.hasOwnProperty('promotion_id')">
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('smsToolboxLargePromotionLabel') }}:
          </label>
          <div>
            <el-autocomplete
              clearable
              :debounce="500"
              :placeholder="placeholder"
              :disabled="readOnly || !loaded"
              v-model="selectedPromotionName"
              :fetch-suggestions="querySearchPromotions"
              @select="updatePromotion"
              @clear="updatePromotion({})"
            />
          </div>
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_amount_required') &&
          config.promotion_amount_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('smsToolboxLargePromotionAmountLabel') }}:
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_amount"
            :disabled="readOnly"
          />
        </div>
      </div>

      <div
        class="form-group"
        v-if="
          config.hasOwnProperty('promotion_duration_required') &&
          config.promotion_duration_required
        "
      >
        <div class="col-md-12">
          <label class="control-label">
            {{ $t('smsToolboxLargePromotionDurationLabel') }}:
          </label>
          <input
            class="form-control"
            type="number"
            v-model="config.promotion_duration"
            :disabled="readOnly"
          />
        </div>
      </div>

      <div v-if="!consentPreferencesAvailable" class="form-group">
        <div>
          <ignore-subscription
            :readOnly="readOnly"
            :value="config.ignore_subscription"
            @input="onIgnoreSubscriptionChange"
          />
        </div>
      </div>

      <div v-if="consentPreferencesAvailable" class="form-group">
        <div class="col-md-6">
          <label>{{ $t('consentPreferenceType') }}:</label>
          <toggle-button
            :disabled="readOnly"
            color="#1d87c8"
            v-model="config.consent_preference.enabled"
          />
          <select-consent-preference
            v-if="config.consent_preference.enabled"
            :value="config.consent_preference.preference"
            :channel="ConsentChannels.SMS"
            :preferences="consentPreferences"
            :read-only="readOnly"
            @input="updateConsentPreference"
          />
        </div>
      </div>
    </tab>
    <tab :position="2" icon="sms" id="sms" style="padding: 20px">
      <div style="overflow: scroll">
        <div class="col-md-6">
          <div v-for="lang in sortedLanguages" :key="lang">
            <sms-message
              v-model="config.messages[lang]"
              :profile-attributes="profileAttributes"
              :is-default="lang === config.defaultLang"
              :lang="lang"
              :disabled="!isAvailable"
              :unsubscribe-url="generateUnsubscribeUrl"
              :optOutReplyEnabled="config.include_opt_out_reply"
              :optOutMessage="getOptOutMessage(lang)"
              :shorten-url="getShortenedLink"
              :with-voucher-codes="(config.voucher_code_enabled || 0) === 1"
            />
          </div>
          <div class="form-group" v-if="voucherCodesEnabled">
            <div class="col-md-12 VoucherOption">
              <label>{{ $t('smsToolboxLargeVoucherCodeType') }}:</label>
              <select-barcode-type
                :disabled="!isAvailable"
                v-model="config.voucher_code_enabled"
              />
            </div>
            <div
              v-if="(config.voucher_code_enabled || 0) === 1"
              class="col-md-12 VoucherOption"
            >
              <label>
                {{ $t('smsToolboxLargeVoucherCodePositionLabel') }}:
              </label>
              <select-barcode-position
                :disabled="!isAvailable"
                v-model="config.voucher_code_position"
              />
            </div>
          </div>
          <div class="form-group" v-if="smsOptOutEnabled && !smsOptOutType">
            <label>{{ $t('smsToolboxLargeUnsubscribeOptionsLabel') }}:</label>
            <div>
              <toggle-button
                color="#1d87c8"
                :value="config.include_unsubscribe"
                @change="setUnsubscribeToggle"
                :disabled="readOnly"
                :sync="true"
              />
              <span style="margin-left: 10px">
                {{ $t('smsToolboxLargeIncludeUnsubscribeLinkLabel') }}
              </span>
            </div>
          </div>
          <div
            class="form-group"
            v-if="smsOptOutEnabled && smsOptOutType && vonageEnabled"
          >
            <label>{{ $t('smsToolboxLargeOptOutOptionLabel') }}:</label>
            <div>
              <toggle-button
                color="#1d87c8"
                v-model="config.include_opt_out_reply"
                :disabled="readOnly"
              />
              <span style="margin-left: 10px">
                {{ $t('smsToolboxLargeIncludeOptOutReply') }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <device-push-preview>
            <device-sms-message-preview
              :fromName="config.messages[config.defaultLang].fromName"
              :text="messageText"
              :unsubscribe-url="generateUnsubscribeUrl"
              :optOutMessage="getOptOutMessage(config.defaultLang)"
              :shorten-url="getShortenedLink"
            />
          </device-push-preview>
        </div>
      </div>
      <div v-if="!readOnly" class="col-md-12">
        <template-section v-model="config" :message-type="MessageTypes.SMS" />
      </div>
    </tab>
  </tabs>
</template>
<script>
import SelectConsentPreference from '@/components/SelectConsentPreference'
import DeviceSmsMessagePreview from '@/components/DeviceSmsMessagePreview'
import DevicePushPreview from '@/components/DevicePushPreview'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import SmsMessage from '@/components/SmsMessage'
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'
import ISO6391 from 'iso-639-1'
import SelectBarcodeType from '@/components/SelectBarcodeType'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings.vue'
import TemplateSection from '@/views/WorkflowEdit/ToolboxesLarge/TemplateSection'
import { MessageTypes } from './MessageTypesEnum'
import XpApi from '@/libs/XpApi'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import _ from 'lodash'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi', 'contentApi'],

  components: {
    TemplateSection,
    IgnoreSubscription,
    SelectBarcodeType,
    SelectBarcodePosition,
    DeviceSmsMessagePreview,
    DevicePushPreview,
    Tabs,
    Tab,
    LanguageSelector,
    MultiLanguageSelector,
    SmsMessage,
    NationalExclusionCheckSettings,
    SelectConsentPreference
  },

  mounted() {
    if (!this.smsOptOutEnabled || !this.vonageEnabled || !this.smsOptOutType) {
      this.config.include_opt_out_reply = false
    }
  },

  data() {
    const config = Object.assign(
      {
        defaultLang: 'en',
        languages: ['en'],
        messages: {
          en: {
            fromName: '',
            text: '',
            optOutMessage: ''
          }
        },
        exclusion: {
          check: false,
          category: null
        },
        include_opt_out_reply: false,
        reply_to_messages: [],
        ignore_subscription: 'default',
        consent_preference: {
          enabled: false,
          preference: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    // maintain BC with old SMS nodes
    if (!config.hasOwnProperty('include_unsubscribe')) {
      config.include_unsubscribe = false
    }

    // maintain BC with old SMS nodes
    if (!config.hasOwnProperty('include_opt_out_reply')) {
      config.include_opt_out_reply = false
    }

    // maintain BC with old SMS nodes
    config.languages.forEach((lang) => {
      if (!config.messages[lang].hasOwnProperty('optOutMessage')) {
        config.messages[lang]['optOutMessage'] = ''
      }
    })

    return {
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      MessageTypes,
      ConsentChannels,
      config
    }
  },

  methods: {
    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }
      this.config.defaultLang = lang
    },

    getOptOutMessage(lang) {
      if (!this.config.include_opt_out_reply) {
        return (this.config.messages[lang].optOutMessage = '')
      }
      let message = this.config.reply_to_messages.find(
        (message) => message.language === lang
      )
      this.config.messages[lang].optOutMessage = message
        ? message['message']
        : ''
      return this.config.messages[lang].optOutMessage
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    setUnsubscribeToggle(toggle) {
      this.config.include_unsubscribe = toggle.value
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion
        ? this.promotionsLabel(selectedPromotion)
        : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required = this.promotion.amount_required
        this.config.promotion_duration_required =
          this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    onIgnoreSubscriptionChange(type) {
      this.config.ignore_subscription = type
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: {
          search: queryString,
          expand: 'can_add,can_remove,integration_name'
        }
      }).then(({ data }) => {
        const list = data.map((item) => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    },

    convertSubscriptionSettings() {
      if (
        this.action.config.ignore_subscription === 'ignore' &&
        this.$store.getters.isFeatureEnabled('consentPreferences')
      ) {
        this.config.ignore_subscription = 'doNotIgnore'
        this.config.consent_preference = {
          enabled: true,
          preference: '0'
        }
        this.updateConfig()
      } else if (
        !this.$store.getters.isFeatureEnabled('consentPreferences') &&
        this.action.config.consent_preference &&
        this.action.config.consent_preference.enabled &&
        this.action.config.consent_preference.preference === '0'
      ) {
        this.config.ignore_subscription = 'ignore'
        this.config.consent_preference = {
          enabled: false,
          preference: null
        }
        this.updateConfig()
      }
    },

    updateConsentPreference(settings) {
      this.config.consent_preference.preference = settings.value
      this.config.consent_preference.preference_name = settings.name
      this.updateConfig()
    },

    promotionsLabel(option) {
      if (_.isEmpty(option)) {
        return ''
      }

      return option.integration_name + " (#" + option.external_id + ") - " + option.name
    }
  },

  computed: {
    placeholder() {
      return this.loaded
        ? this.$t('selectOptionPlaceholder')
        : this.$t('loadingPlaceholder')
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.smsEnabled
    },

    smsOptOutEnabled() {
      return this.$store.getters.isFeatureEnabled('smsOptOutEnabled')
    },

    smsOptOutType() {
      return this.$store.getters.isFeatureEnabled('smsOptOutType')
    },

    smsShortenURL() {
      return this.$store.getters.isFeatureEnabled('smsShortenURL')
    },

    vonageEnabled() {
      return this.$store.getters.isFeatureEnabled('vonageEnabled')
    },

    voucherCodesEnabled() {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    messageText() {
      const messageText = this.config.messages[this.config.defaultLang].text
      if ((this.config.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    sortedLanguages() {
      return [...this.config.languages].sort((lang1, lang2) => {
        if (
          lang1 === this.config.defaultLang ||
          lang2 === this.config.defaultLang
        ) {
          return lang1 === this.config.defaultLang ? -1 : 1
        }
        return ISO6391.getName(lang1).localeCompare(ISO6391.getName(lang2))
      })
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    languages() {
      return this.config.languages
    },

    profileAttributes() {
      let attributes = this.$store.state.project.attributes

      if (attributes.length === 0) {
        return [{}]
      }

      return this.$store.state.project.attributes
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    getShortDomain() {
      return this.$store.getters.getDomain('shortDomain')
    },

    generateUnsubscribeUrl() {
      if (this.getShortDomain && this.config.include_unsubscribe) {
        return `${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    getShortenedLink() {
      if (this.getShortDomain && this.smsShortenURL) {
        return `${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    consentPreferencesAvailable() {
      return this.$store.getters.isFeatureEnabled('consentPreferences')
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              fromName: '',
              text: '',
              optOutMessage: ''
            })
            changes = true
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
            changes = true
          }
        })
      }
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    },
    'config.ignore_subscription': function () {
      this.updateConfig()
    },
    'config.consent_preference': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
    if (this.config.promotion_id) {
      const foundPromotion = this.$store.state.project.promotions.find(
        (promotion) => promotion.id === this.config.promotion_id
      )
      if (foundPromotion === undefined) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: {
            id: this.config.promotion_id,
            expand: 'can_add,can_remove,integration_name'
          }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.updatePromotion({})
          } else {
            this.promotion = data[0]
            this.selectedPromotionName = this.promotionsLabel(data[0])
          }
        })
      } else {
        this.promotion = foundPromotion
        this.selectedPromotionName = this.promotionsLabel(foundPromotion)
      }
    }
    this.$store.dispatch('fetchTemplates', MessageTypes.SMS)
    if (!this.readOnly) {
      this.convertSubscriptionSettings()
    }
    this.loaded = true
  },

  beforeDestroy() {
    if (this.isAvailable) {
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 50%
  padding-top: 2px

textarea
    resize: none

.setup-tab
    padding: 20px
    .multiselect, input
        width: 50%

.VoucherOption
    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px
</style>
